<template>
    <div class="main">
        <div class="top">
            <van-image class="t1" round :src="avatar" fit="fill" />
            <div class="t2">
                <div class="t21">{{ nickName }}</div>
                <div class="t21">ID: {{ invitee }}</div>
            </div>
            <div class="line"></div>
            <div class="t3">邀请你加入</div>
        </div>

        <div class="logo"></div>
        <div class="title1">一对一<span style="color: #FF2F7BFF">私密</span>视频聊天</div>
        <div class="title2">超甜蜜，海量单身男女恋爱约会</div>

        <input class="inputPhone" placeholder="输入您的手机号" @input="textPhoneNumber($event)"/>

        <div v-if="isOk" class="confirm" @click="confirmInvite">
            <div class="txt">接收邀请 免费下载</div>
        </div>
        <div v-else class="unConfirm">
            <div class="txt1">接收邀请 免费下载</div>
        </div>
    </div>
</template>

<script>
import { doRequest } from '@/utils/request'

export default {
    data() {
        return {
            phone: '',
            isOk: false,
            invitee: '',
            nickName: '',
            avatar: '',
        }
    },
    methods: {
        textPhoneNumber(event) {
            this.phone = event.currentTarget.value
            if(!(/^1[23456789]\d{9}$/.test(this.phone))){ 
                this.isOk = false
            }else {
                this.isOk = true
            }
        },
        confirmInvite() {
            doRequest('/invite/confirmPlanB', {
                invitee: this.invitee,
                mobile: this.phone,
            }, this.$route.query).then(() => {
                var link = document.createElement('a');
                link.href='https://apps.apple.com/cn/app/id1489317634';
                link.rel = 'external'
                link.click();
            })
        },
    },
    mounted() {
        this.invitee = this.$route.query['userId']
        console.log(this.invitee)

        doRequest('/user/getInfo', {
            target_user_id: this.invitee,
        }, this.$route.query).then(res => {
            this.nickName = res.user.nick_name
            this.avatar = res.user.avatar
        })
    }
}
</script>

<style scoped>
input::-ms-input-placeholder{
    text-align: center;
    font-size: 19px;
    color: #999999;
}
input::-webkit-input-placeholder{
    text-align: center;
    font-size: 19px;
    color: #999999;
}

.main {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    width: 375px;
    height: 812px;

    background: url('../../assets/inviteEarnGold/pic_bg.png');
    background-repeat: no-repeat ;
    background-position: center;
    background-size: 100% ;
}

.top {
    width: 375px;
    height: 70px;
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 1, 0.53);
}

.top .t1 {
    width: 47px;
    height: 47px;
    border-radius: 50%;
    background: black;
    margin-left: 10px
}

.top .t2 {
    height: 47px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 10px;
}

.top .t2 .t21 {
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
}

.top .line {
    margin-left: 15px;
    width: 1px;
    height: 35px;
    background: #4A4A4A;
}

.top .t3 {
    margin-left: 21px;
    font-size: 17px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
}

.logo {
    width: 63px;
    height: 102px;
    margin-top: 75px;
    /* margin-left: 153px; */

    background: url('../../assets/inviteEarnGold/logo.png');
    background-repeat: no-repeat ;
    background-position: center;
    background-size: 100% ;
}

.title1 {
    margin-top: 14px;
    height: 15px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
}

.title2 {
    margin-top: 60px;
    height: 19px;
    font-size: 19px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
}

.inputPhone {
    margin-top: 42px;
    width: 300px;
    height: 55px;
    background: #FFFFFF;
    border-radius: 10px;
    font-size: 19px;
    text-align: center;
    color: #333333;
}

.confirm {
    margin-top: 48px;
    width: 235px;
    height: 60px;
    background: linear-gradient(-90deg, #FD4391 0%, #FE1C6D 100%);
    border-radius: 30px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.unConfirm {
    margin-top: 48px;
    width: 235px;
    height: 60px;
    background: #666666;
    border-radius: 30px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.confirm .txt {
    font-size: 17px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
}

.unConfirm .txt1 {
    font-size: 17px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
}
</style>